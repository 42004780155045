.chips{
    display: flex; 
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px;
    /* min-width: 200px; */
}
.chips img{
    height: 10px;
    margin: 6px;
}