.stock__dot{
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    display: inline-block;
}
.stock__dot--empty{
    border: 1px solid var(--grey);
}
.stock__dot--full{
    border: 1px solid var(--primary);
    background-color: var(--primary);
}


.stock__range {
  width: 100%; /* Width of the outside container */
  margin-top: -15px;
}
.range{
    position: relative;
}
.range__scale{
    position: absolute;
}
.range__scale--one{
    left: 0;
}
.range__scale--two{
    left: 30%;
}
.range__scale--three{
    left: 61%;
}
.range__scale--four{
    left: 93%;
}


/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 210px !important; /* Full-width */
  height: 1px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  padding: 0 !important;
}
@media (min-width: 575px){
    .slider{
        width: 300px !important;
    }
}
/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 33.3333%; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: transparent;
  border-left: 5px solid var(--primary);
  border-right: 5px solid var(--primary);
  cursor: pointer; /* Cursor on hover */
  border-radius: 2px;
}

.slider::-moz-range-thumb {
    width: 85px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: transparent;
  border-left: 5px solid var(--primary);
  border-right: 5px solid var(--primary);
  cursor: pointer; /* Cursor on hover */
  border-radius: 2px;
}
