.quantity{
    display: flex;
    align-items: center;
}
.quantity__control{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: white;
    font-weight: 600;
    font-size: 1.8rem;
    cursor: pointer;
    transition: var(--transition);
}
.quantity__control:hover{
    background-color: var(--primary);
    color: white;
}
.quantity__number, .quantity__unit{
    font-weight: 600;
    font-size: 1.6rem;
}