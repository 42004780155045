.loader{
    position: fixed;
    display: flex;
    height: 100%;
    z-index: 10000000;
    justify-content: center;
    align-items: center;
    background: white;
    /* background: rgba(0,0,0,0.4); */
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
