.product .product__carousel{
    background-color: white;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    border-radius: 1rem;
    transition: var(--transition);
}
.product .product__img img{
    max-height: 40rem;
    max-width: 100%;
}
.product .carousel-item > div {
    min-height: 40rem;
}
.product__main .add-to-cart{
    right: 1.5rem;
    bottom: 0;
    top: inherit;
}
.product__related .product__img img{
    max-height: 15rem;
    max-width: 100%;
}
.related__img{
    min-height: 15rem;
    text-align: center;
}

.related__link, .related__link:hover{
    color: var(--text);
}
.product__related .add-to-cart{
    width: 4.5rem;
    height: 4.5rem;
    right: -1rem;
}
@media (min-width: 768px){
    .product__related .add-to-cart{
        right: -2rem;
    }
}
