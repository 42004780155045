.product-list{
    display: none;
}
.product-list.active{
    display: block;
}
.carousel-item > div{
    min-height: 22rem;
}
.product-list .product__carousel .product__img img{
    max-height: 22rem;
    max-width: 100%;
}
.product-list__item{
    position: relative;
}
.product-list__separator{
    width: 5rem;
    height: 0.1rem;
    background-color: var(--textLight);
    display: block;
    margin-top: 1.5rem;
}
.product-list__desc--secondary{
    color: var(--textLight);
}
.product-list .price__label{
    font-size: 1.2rem;
}
.product-list .price__main{
    font-size: 2rem;
}
.product-list .price__discount{
    font-size: 1.5rem;
}
.product-list .stock__label{
    font-size: 1.2rem;
}
.product-list .stock__level > p{
    font-size: 1.2rem;
}
.product-list .stock__dot{
    width: 1rem;
    height: 1rem;
}  

.product-list-title{
    max-width: 220px;
}

.list-view{
    display: flex;
}
.list-view__item{
    cursor: pointer;
}
.list-view__item.active .list-view__icon path{
    fill: var(--primary);
}
