.stock__label{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
}
.stock__level > p{
    font-size: 1.4rem;
    color: var(--primary);
}
.stock__level{
    display: flex;
    align-items: center;
}
.stock__dot{
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 1px solid var(--textLight);
    display: inline-block;
    margin-right: 0.5rem;
}
.stock__full{
    border: 1px solid var(--primary);
    background-color: var(--primary);
}