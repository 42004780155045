.loader{
    position: fixed;
    display: flex;
    height: 100%;
    z-index: 10000000;
    justify-content: center;
    align-items: center;
    background: white;
    /* background: rgba(0,0,0,0.4); */
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.header{
    background-color: var(--primary);
}
.menu__item{
    transition: var(--transition);
}
.menu__item:hover{
    background-color: var(--primaryDark);
}
.menu__link:hover{
    text-decoration: none;
}
.menu__item--active{
    background-color: var(--primaryLight);
}
.menu__title{
    color: white;
}
.minicart{
    margin: 2rem 0;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    width: auto !important;
}
.minicart__counter{
    font-size: 1.4rem;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    background-color: var(--primary);
    position: absolute;
    width: 2.1rem;
    height: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 3px;
}
.minicart__link, .minicart__remove, .logout{
    transition: var(--transition);
}
.minicart__link:hover, .minicart__remove:hover, .logout:hover{
    opacity: 0.5;
}
.notifications{
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    background-color: white;
    transition: var(--transition);
}
.notifications:hover{
    background-color: var(--primaryDark);
}
.notifications__counter{
    font-size: 1rem;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    background-color: var(--primary);
    position: absolute;
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 9px;
    right: 18px;
}
.lang .dropdown-toggle{
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.lang .lang__select{
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: none;
    border: 0;
}

/* Header mobile */
.header-mobile {
    z-index: 999999 !important;
}
.header-mobile .header__logo img {
    max-width: 17rem;
}
@media (min-width: 576px){
    .header-mobile .header__logo img {
        max-width: none;
    }
}

.header-mobile__inner {
    width: 250px;
    right: -250px;
    z-index: 99;
    transition: var(--transition);
    overflow: hidden;
    top: 0;
    background-color: var(--primary);
    padding: 1.5rem 0;
    padding-top: 10rem;
    box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
}

.header-mobile__hidden {
    overflow: auto;
    padding-bottom: 100px;
    height: 100vh;
}

.header-mobile__inner.show-menu {
    right: 0;
}

html body .header-mobile .header__menu__item a {
    color: var(--textLight);
}

.hamburger {
    background-color: var(--primaryDark);
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    border: 0;
    margin: 0;
    overflow: visible;
    z-index: 999;
    right: 15px;
    top: 20px;
    display: flex;
}
.hamburger.is-active{
    background-color: transparent;
}


.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: white;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.footer__top{
    background-color: white;
}
.footer .h3{
    color: #5a5a5a;
}
.footer__mail a{
    color: #5a5a5a;
}
@media (min-width: 768px){
    .footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
.nav__item{
    margin-bottom: 1.5rem;
    width: 100%;
}
.nav__link{
    font-size: 1.6rem;
    display: inline-block;
    transition: var(--transition);
}
.nav__link:hover{
    color: var(--text);
}
.nav__item.active .nav__link{
    color: var(--text);
}
html body .nav__item--dropdown::after{
    display: none;
}
.nav__item.active .nav__link::after{
    content: '';
    width: 100%;
    height: 3px;
    background-color: var(--text);
    display: block;
}
.nav--mobile{
    position: fixed;
    z-index: 999999;
    background: var(--light);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 5rem;
    overflow-y: scroll;
}
.nav__close{
    position: absolute;
    right: 3rem;
    top: 3rem;
    cursor: pointer;
}
.nav__trigger{
    position: fixed;
    bottom: 3rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
}
.dropdown__chevron{
    color: var(--textLight);
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    border-top: .4em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.nav__item--dropdown .nav__link{
    display: flex;
    align-items: center;
}
.confirmationModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 1000;
    border-radius: 16px;
  }
.confirmationModal button {
    margin:0px 16px 16px 16px;
  }
.confirmationModal p {
    margin:16px;
    text-align: center;
  }
.confirmationModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000ba;
    z-index: 9999;
  }
.chips{
    display: flex; 
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px;
    /* min-width: 200px; */
}
.chips img{
    height: 10px;
    margin: 6px;
}
.client__action{
    cursor: pointer;
    background-color: var(--primary);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
thead th{
    text-align: center;
    font-weight: 400;
    color: var(--primary);
}
.admin-clients__table{
    min-width: 450px;
}


html body .content .search{
    display: flex;
    align-items: center;
    position: relative;
}
html body .content .search__input{
    width: 100%;
    border: none;
    background-color: white;
    padding: 2rem 3rem;
    padding-left: 7rem;
    border-radius: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--text);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    transition: var(--transition);
}
html body .content .search__input:focus{
    outline: 0;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}
html body .content .search__input:hover{
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}
html body .content .search::before{
    content: url(/static/media/szukaj.77f7d0dd.svg);
    position: absolute;
    left: 3rem;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.stock__dot{
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    display: inline-block;
}
.stock__dot--empty{
    border: 1px solid var(--grey);
}
.stock__dot--full{
    border: 1px solid var(--primary);
    background-color: var(--primary);
}


.stock__range {
  width: 100%; /* Width of the outside container */
  margin-top: -15px;
}
.range{
    position: relative;
}
.range__scale{
    position: absolute;
}
.range__scale--one{
    left: 0;
}
.range__scale--two{
    left: 30%;
}
.range__scale--three{
    left: 61%;
}
.range__scale--four{
    left: 93%;
}


/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 210px !important; /* Full-width */
  height: 1px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  padding: 0 !important;
}
@media (min-width: 575px){
    .slider{
        width: 300px !important;
    }
}
/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 33.3333%; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: transparent;
  border-left: 5px solid var(--primary);
  border-right: 5px solid var(--primary);
  cursor: pointer; /* Cursor on hover */
  border-radius: 2px;
}

.slider::-moz-range-thumb {
    width: 85px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: transparent;
  border-left: 5px solid var(--primary);
  border-right: 5px solid var(--primary);
  cursor: pointer; /* Cursor on hover */
  border-radius: 2px;
}

.discounts__table{
    min-width: 450px;
}
.discount__action{
    cursor: pointer;
    background-color: var(--primary);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
thead th{
    text-align: center;
    font-weight: 400;
    color: var(--primary);
}
td{
    vertical-align: middle !important;
}
.admin-clients__table{
    min-width: 450px;
}
html body .pricing .rounded-box .search__input{
    box-shadow: none;
    border: 1px solid var(--grey);
}
.pricing__image{
    width: 20rem;
    height: 10rem;
    border: 1px solid var(--grey);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image__delete{
    border-radius: 50%;
    border: 1px solid var(--grey);
    width: 2.5rem;
    height: 2.5rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 5px;
}
.image__delete img{
    width: 0.8rem;
}
.selected-product-inputs{
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-product-inputs input{
    margin-right:8px;
}
.pagination__item, .pagination__prev, .pagination__next{
    font-weight: 600;
    font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text);
    cursor: pointer;
}
.pagination__item.active{
    background-color: var(--primary);
    border-radius: 50%;
    color: white;
    font-weight: 700;
}
.action-button {
    display: flex;
    padding-bottom: 10px;
    align-items: flex-end;
}
.client__label{
    font-size: 1.2rem;
    text-transform: uppercase;
}
.client__value{
    font-size: 1.6rem;
    font-weight: 700;
}
.invoice__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}
.order__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}
.order__action{
    cursor: pointer;
    background-color: var(--primary);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
thead th{
    text-align: center;
    font-weight: 400;
    color: var(--primary);
}

.news__discount {
    color: var(--primary);
    font-weight: 700;
}
.news__text{
    line-height: 2.8rem;
}
.news__more{
    color: var(--primary);
    text-transform: uppercase;
}
.dashboard__balance{
    font-size: 2rem;
}
.carousel-control-prev, .carousel-control-next{
    width: auto;
}
.carousel-control-prev > span, .carousel-control-next > span{
    color: white;
    font-size: 2.5rem;
    background-color: var(--textLight);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-to-cart{
    width: 6rem;
    height: 6rem;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
    cursor: pointer;
    transition: var(--transition);
    position: absolute;
    top: 3rem;
    right: -1rem;
    z-index: 2;

}
@media (min-width: 768px){
    .add-to-cart{
        right: -2.5rem;
    }
}
.add-to-cart:hover{
    background-color: var(--primaryDark);
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
}
.price{
    display: flex;
    align-items: baseline;
}
.price__main{
    font-size: 3.2rem;
    font-weight: 700;
    color: var(--primary);
}
.price__small{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary);
}
.price__discount{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--textLight);
    position: relative;
    display: flex;
    align-items: center;
}
.price__discount::after{
    content: '';
    width: 100%;
    height: 0.2rem;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.price__label{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
}
.stock__label{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
}
.stock__level > p{
    font-size: 1.4rem;
    color: var(--primary);
}
.stock__level{
    display: flex;
    align-items: center;
}
.stock__dot{
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    border: 1px solid var(--textLight);
    display: inline-block;
    margin-right: 0.5rem;
}
.stock__full{
    border: 1px solid var(--primary);
    background-color: var(--primary);
}
.product-list{
    display: none;
}
.product-list.active{
    display: block;
}
.carousel-item > div{
    min-height: 22rem;
}
.product-list .product__carousel .product__img img{
    max-height: 22rem;
    max-width: 100%;
}
.product-list__item{
    position: relative;
}
.product-list__separator{
    width: 5rem;
    height: 0.1rem;
    background-color: var(--textLight);
    display: block;
    margin-top: 1.5rem;
}
.product-list__desc--secondary{
    color: var(--textLight);
}
.product-list .price__label{
    font-size: 1.2rem;
}
.product-list .price__main{
    font-size: 2rem;
}
.product-list .price__discount{
    font-size: 1.5rem;
}
.product-list .stock__label{
    font-size: 1.2rem;
}
.product-list .stock__level > p{
    font-size: 1.2rem;
}
.product-list .stock__dot{
    width: 1rem;
    height: 1rem;
}  

.product-list-title{
    max-width: 220px;
}

.list-view{
    display: flex;
}
.list-view__item{
    cursor: pointer;
}
.list-view__item.active .list-view__icon path{
    fill: var(--primary);
}

.quantity{
    display: flex;
    align-items: center;
}
.quantity__control{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: white;
    font-weight: 600;
    font-size: 1.8rem;
    cursor: pointer;
    transition: var(--transition);
}
.quantity__control:hover{
    background-color: var(--primary);
    color: white;
}
.quantity__number, .quantity__unit{
    font-weight: 600;
    font-size: 1.6rem;
}
.product .product__carousel{
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    border-radius: 1rem;
    transition: var(--transition);
}
.product .product__img img{
    max-height: 40rem;
    max-width: 100%;
}
.product .carousel-item > div {
    min-height: 40rem;
}
.product__main .add-to-cart{
    right: 1.5rem;
    bottom: 0;
    top: inherit;
}
.product__related .product__img img{
    max-height: 15rem;
    max-width: 100%;
}
.related__img{
    min-height: 15rem;
    text-align: center;
}

.related__link, .related__link:hover{
    color: var(--text);
}
.product__related .add-to-cart{
    width: 4.5rem;
    height: 4.5rem;
    right: -1rem;
}
@media (min-width: 768px){
    .product__related .add-to-cart{
        right: -2rem;
    }
}

.cart__back{
    font-weight: 600;
    color: var(--text);
    font-size: 1.6rem;
}
.address{
    cursor: pointer;
}
.address__label{
    font-weight: 700;
}
.cart__address input[type="radio"]{
    position: absolute;
    right: 40px;
}
.address{
    transition: var(--transition);
}
.address.active{
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}
.cart__quantity{
    font-weight: 700;
}
.cart__items .product__img img{
    height: 6rem;
}
.cart .price__main {
    font-size: 2.8rem;
}
.cart .price__discount {
    font-size: 1.5rem;
}
.cart .quantity__control{
    background-color: var(--primary);
    color: white;
}
.cart .quantity__control:hover{
    background-color: var(--primaryDark);
}
.cart__remove{
    cursor: pointer;
    transition: var(--transition);
}
.cart__remove:hover{
    opacity: 0.5;
}
.cart__total{
    border-top: 1px solid var(--grey);
}
.total__price{
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--primary);
}
.price-before-discount {
    text-decoration: line-through;
    font-size: 17px !important;
}
.summary__back{
    font-weight: 600;
    color: var(--text);
    font-size: 1.6rem;
}
.summary__number{
    font-weight: 600;
}
.order__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}
.total__price{
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--primary);
}
.order__item .product__img img{
    height: 6rem;
}
.order .price__main {
    font-size: 2.8rem;
}
.order .price__discount {
    font-size: 1.5rem;
}
.invoice__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}
.invoice__status {
    color: var(--primary);
    display: flex;
    align-items: center;
}
.total__price{
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--primary);
}
.account__label{
    font-size: 1.2rem;
    text-transform: uppercase;
}
.account__value{
    font-size: 1.6rem;
    font-weight: 700;
}
.account__address--edit{
    top: -20px;
    background: var(--light);
    z-index: 1;
    position: relative;
}
.edit__trigger{
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--primary);
    font-weight: 400;
}
/* Variables */
:root {
    --primary: #252525 !important;
    --primaryDark: #515151cc;
    --primaryLight: #777575;
    --secondary: #a1a1a1 !important;
    --secondaryDark: #8a8a8a !important;
    --text: #212121;
    --textLight: #939393;
    --light: #ecf2fa !important;
    --grey: #d2d2d2;
    --transition: 0.35s ease;
    --error: #f44336;
}
.cursor-pointer{
    cursor: pointer !important;
}

.zi-1{
    z-index: 1 !important;
}
.bg--light{
    background-color: #ecf2fa;
    background-color: var(--light);
}

.bg--primary{
    background-color: #252525;
    background-color: var(--primary);
}

.text--primary{
    color: #252525 !important;
    color: var(--primary) !important;
}

.text--primary-dark{
    color: #515151cc !important;
    color: var(--primaryDark) !important;
}

.font-weight-600{
    font-weight: 600 !important;
}

.mb-50{
    margin-bottom: 5rem !important;
}

.mt-50{
    margin-top: 5rem !important;
}
.pb-50{
    padding-bottom: 5rem !important;
}

.pt-50{
    padding-top: 5rem !important;
}
.border--light{
    border: 1px solid #d2d2d2 !important;
    border: 1px solid var(--grey) !important;
}
.border--error{
    border: 1px solid red !important;
}
.rounded-box{
    background-color: white;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    border-radius: 1rem;
    transition: 0.35s ease;
    transition: var(--transition);
}
.rounded-box:hover{
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}

@media (min-width: 575px){
    html body .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
}

html {
    font-size: 62.5%;
}

/* Typography */

html body {
    font-size: 1.4rem;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #212121;
    color: var(--text);
    background: #ecf2fa;
    background: var(--light);
    position: relative;
}
@media (min-width: 768px){
    html body {
        padding-bottom: 126px;
        min-height: 100vh;
    }
}

html body p{
    margin: 0;
}

html body a{
    color: #212121;
    color: var(--text);
    transition: 0.35s ease;
    transition: var(--transition);
}

html body a:hover{
    color: #212121;
    color: var(--text);
    opacity: 0.5;
    text-decoration: none;
}

html body .content{
    margin: 3rem 0;
}

html body h1, html body .h1{
    font-size: 3rem;
    font-weight: 700;
}

html body h2, html body .h2{
    font-size: 2rem;
    font-weight: 600;
}

html body h3, html body .h3{
    font-size: 1.7rem;
    font-weight: 600;
}
html body h4, html body .h4{
    font-size: 1.4rem;
    font-weight: 600;
}

/* Buttons */

html body .btn{
    border-radius: 10rem;
    padding: 1rem 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    min-width: 18.5rem;
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.2);
}
html body .btn:hover{
    box-shadow: 0px 6px 8px 0px rgba(0,0,0,0.3);
}
html body .btn--primary{
    background-color: #252525;
    background-color: var(--primary);
    color: white;
}
html body .btn--primary:hover{
    background-color: #515151cc;
    background-color: var(--primaryDark);
    color: white;
}
html body .btn--secondary{
    background-color: #a1a1a1;
    background-color: var(--secondary);
    color: white;
}
html body .btn--secondary:hover{
    background-color: #8a8a8a;
    background-color: var(--secondaryDark);
    color: white;
}
html body .btn--datepicker{
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    background-color: #252525;
    background-color: var(--primary);
    border: 0;
    color: white;
    transition: 0.35s ease;
    transition: var(--transition);
    border-radius: 50%;
}
html body .btn--datepicker:focus{
    outline: 0;
}

/* Dropdown */

.dropdown .dropdown-menu{
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);
    font-size: 1.2rem;
    border: none;
}

/* Nav */
li {
    list-style-type: none;
}
html body .nav__dropdown{
    list-style-type: none;
    padding-left: 1rem;
}
html body .nav__item{
    list-style-type: none
}
html body .nav__item--dropdown{
    display: flex;
    align-items: center;
}
html body .nav__item--dropdown::after{
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    /* content: ""; */
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

/* Inputs */

html body label{
    width: 100%;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-left: 1.5rem;
}

html body input:not([type="radio"]):not([type="checkbox"]):not([type="search"]){
    width: 100%;
    border-radius: 4rem;
    border: 0;
    outline: 0;
    padding: 1rem 3rem;
    transition: 0.35s ease;
    transition: var(--transition);
}

html body textarea{
    width: 100%;
    border-radius: 1rem;
    border: 0;
    outline: 0;
    padding: 3rem;
    min-height: 17rem;
    transition: 0.35s ease;
    transition: var(--transition);
}

html body input:not([type="radio"]):focus, html body textarea:focus{
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}

html body input[type="radio"]{
    width: 2rem;
    height: 2rem;
}

html body .datepicker input{
    cursor: pointer;
    max-width: 14rem;
}

html body .optional__label{
    position: absolute;
    right: 3rem;
    top: 3.6rem;
    font-size: 1.2rem;
}

html body .select{
    display: flex;
    align-items: center;
}

html body select{
    width: 100%;
    border-radius: 4rem;
    border: 0;
    outline: 0;
    padding: 1rem 3rem;
    transition: 0.35s ease;
    transition: var(--transition);
    border: 1px solid #d2d2d2 !important;
    border: 1px solid var(--grey) !important;
}

html body .dropdown--select{
    width: 100%;
}

html body .select__label{
    text-transform: uppercase;
}

html body .select .dropdown-menu{
    width: 100%;
}

html body .dropdown--select .dropdown-toggle{
    width: 100%;
    border-radius: 4rem;
    padding: 1rem 3rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #939393;
    color: var(--textLight);
}

html body .input--error label{
    color: var(--danger);
}

html body .input--error input{
    border: 1px solid var(--danger) !important;
}

html body .input--error .error__message{
    font-size: 1.2rem;
    color: var(--danger);
    margin-top: 0.5rem;
    margin-left: 1.5rem;
}

/* Input file */
input[type="file"]{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    max-width: 100%;
}

/* Switch */
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(#d2d2d2, 0.87);
    color: rgba(var(--grey, 0, 0, 0), 0.87);
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    width: 156px;
}

/* Input */
.pure-material-switch > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -10px;
    display: block;
    margin: 0;
    border-radius: 40px;
    width: 80px;
    height: 60px;
    background-color: rgba(#d2d2d2, 0.38);
    background-color: rgba(var(--grey, 0, 0, 0), 0.38);
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch > span {
    display: inline-block;
    width: 156px;
    cursor: pointer;
    position: relative;
}

/* Track */
.pure-material-switch > span::before {
    content: "";
    display: inline-block;
    border-radius: 40px;
    width: 156px;
    height: 40px;
    background-color: #d2d2d2;
    background-color: var(--grey);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch > span::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 105px;
    border-radius: 40px;
    width: 40px;
    height: 28px;
    background-color: rgb(255, 255, 255);
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    /* box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch > input:checked {
    right: -10px;
    left: inherit;
    background-color: #252525;
    background-color: var(--primary);
}

.pure-material-switch > input:checked + span::before {
    background-color: #252525;
    background-color: var(--primary);
}

.pure-material-switch > input:checked + span::after {
    background-color: white;
    transform: translateX(96px);
}

/* Hover, Focus */
.pure-material-switch:hover > input {
    opacity: 0.04;
}

.pure-material-switch > input:focus {
    opacity: 0.12;
}

.pure-material-switch:hover > input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-switch > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch > input:active + span::before {
    background-color: #252525;
    background-color: var(--primary);
}

.pure-material-switch > input:checked:active + span::before {
    background-color: #252525;
    background-color: var(--primary);
}

/* Disabled */
.pure-material-switch > input:disabled {
    opacity: 0;
}

.pure-material-switch > input:disabled + span {
    color: rgb(#d2d2d2);
    color: rgb(var(--grey, 0, 0, 0));
    opacity: 0.38;
    cursor: default;
}

.pure-material-switch > input:disabled + span::before {
    background-color: rgba(#d2d2d2, 0.38);
    background-color: rgba(var(--grey, 0, 0, 0), 0.38);
}

.pure-material-switch > input:checked:disabled + span::before {
    background-color: rgba(33, 150, 243, 0.6);
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}


/* Tables */
html body .table-striped tbody tr:nth-of-type(odd){
    background-color: #f6f9fd;
}

html body .table td, html body .table th{
    border: 0;
}

/* Badge */

html body .badge{
    font-weight: 400;
    font-size: 1.4rem;
    width: 100%;
    padding: 0.5rem 1.5rem;
}

html body .badge--secondary{
    background-color: #d2d2d2;
    background-color: var(--grey);
}
/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

 .datepicker {
    padding: 4px;
    border-radius: 4px;
    direction: ltr;
  }
  .datepicker-inline {
    width: 220px;
  }
  .datepicker-rtl {
    direction: rtl;
  }
  .datepicker-rtl.dropdown-menu {
    left: auto;
  }
  .datepicker-rtl table tr td span {
    float: right;
  }
  .datepicker-dropdown {
    top: 0;
    left: 0;
  }
  .datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  .datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
  }
  .datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
  }
  .datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
  }
  .datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
  }
  .datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
  }
  .datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
  }
  .datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
  }
  .datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
  }
  .datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
  }
  .datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .datepicker td,
  .datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: none;
  }
  .table-striped .datepicker table tr td,
  .table-striped .datepicker table tr th {
    background-color: transparent;
  }
  .datepicker table tr td.day:hover,
  .datepicker table tr td.day.focused {
    background: #eee;
    cursor: pointer;
  }
  .datepicker table tr td.old,
  .datepicker table tr td.new {
    color: #999;
  }
  .datepicker table tr td.disabled,
  .datepicker table tr td.disabled:hover {
    background: none;
    color: #999;
    cursor: default;
  }
  .datepicker table tr td.highlighted {
    background: #d9edf7;
    border-radius: 0;
  }
  .datepicker table tr td.today,
  .datepicker table tr td.today:hover,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000;
  }
  .datepicker table tr td.today:hover,
  .datepicker table tr td.today:hover:hover,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today.disabled:hover:hover,
  .datepicker table tr td.today:active,
  .datepicker table tr td.today:hover:active,
  .datepicker table tr td.today.disabled:active,
  .datepicker table tr td.today.disabled:hover:active,
  .datepicker table tr td.today.active,
  .datepicker table tr td.today:hover.active,
  .datepicker table tr td.today.disabled.active,
  .datepicker table tr td.today.disabled:hover.active,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today:hover.disabled,
  .datepicker table tr td.today.disabled.disabled,
  .datepicker table tr td.today.disabled:hover.disabled,
  .datepicker table tr td.today[disabled],
  .datepicker table tr td.today:hover[disabled],
  .datepicker table tr td.today.disabled[disabled],
  .datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
  }
  .datepicker table tr td.today:active,
  .datepicker table tr td.today:hover:active,
  .datepicker table tr td.today.disabled:active,
  .datepicker table tr td.today.disabled:hover:active,
  .datepicker table tr td.today.active,
  .datepicker table tr td.today:hover.active,
  .datepicker table tr td.today.disabled.active,
  .datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069 \9;
  }
  .datepicker table tr td.today:hover:hover {
    color: #000;
  }
  .datepicker table tr td.today.active:hover {
    color: #fff;
  }
  .datepicker table tr td.range,
  .datepicker table tr td.range:hover,
  .datepicker table tr td.range.disabled,
  .datepicker table tr td.range.disabled:hover {
    background: #eee;
    border-radius: 0;
  }
  .datepicker table tr td.range.today,
  .datepicker table tr td.range.today:hover,
  .datepicker table tr td.range.today.disabled,
  .datepicker table tr td.range.today.disabled:hover {
    background-color: #f3d17a;
    background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border-radius: 0;
  }
  .datepicker table tr td.range.today:hover,
  .datepicker table tr td.range.today:hover:hover,
  .datepicker table tr td.range.today.disabled:hover,
  .datepicker table tr td.range.today.disabled:hover:hover,
  .datepicker table tr td.range.today:active,
  .datepicker table tr td.range.today:hover:active,
  .datepicker table tr td.range.today.disabled:active,
  .datepicker table tr td.range.today.disabled:hover:active,
  .datepicker table tr td.range.today.active,
  .datepicker table tr td.range.today:hover.active,
  .datepicker table tr td.range.today.disabled.active,
  .datepicker table tr td.range.today.disabled:hover.active,
  .datepicker table tr td.range.today.disabled,
  .datepicker table tr td.range.today:hover.disabled,
  .datepicker table tr td.range.today.disabled.disabled,
  .datepicker table tr td.range.today.disabled:hover.disabled,
  .datepicker table tr td.range.today[disabled],
  .datepicker table tr td.range.today:hover[disabled],
  .datepicker table tr td.range.today.disabled[disabled],
  .datepicker table tr td.range.today.disabled:hover[disabled] {
    background-color: #f3e97a;
  }
  .datepicker table tr td.range.today:active,
  .datepicker table tr td.range.today:hover:active,
  .datepicker table tr td.range.today.disabled:active,
  .datepicker table tr td.range.today.disabled:hover:active,
  .datepicker table tr td.range.today.active,
  .datepicker table tr td.range.today:hover.active,
  .datepicker table tr td.range.today.disabled.active,
  .datepicker table tr td.range.today.disabled:hover.active {
    background-color: #efe24b \9;
  }
  .datepicker table tr td.selected,
  .datepicker table tr td.selected:hover,
  .datepicker table tr td.selected.disabled,
  .datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    background-image: linear-gradient(to bottom, #b3b3b3, #808080);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: #808080 #808080 #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td.selected:hover,
  .datepicker table tr td.selected:hover:hover,
  .datepicker table tr td.selected.disabled:hover,
  .datepicker table tr td.selected.disabled:hover:hover,
  .datepicker table tr td.selected:active,
  .datepicker table tr td.selected:hover:active,
  .datepicker table tr td.selected.disabled:active,
  .datepicker table tr td.selected.disabled:hover:active,
  .datepicker table tr td.selected.active,
  .datepicker table tr td.selected:hover.active,
  .datepicker table tr td.selected.disabled.active,
  .datepicker table tr td.selected.disabled:hover.active,
  .datepicker table tr td.selected.disabled,
  .datepicker table tr td.selected:hover.disabled,
  .datepicker table tr td.selected.disabled.disabled,
  .datepicker table tr td.selected.disabled:hover.disabled,
  .datepicker table tr td.selected[disabled],
  .datepicker table tr td.selected:hover[disabled],
  .datepicker table tr td.selected.disabled[disabled],
  .datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #808080;
  }
  .datepicker table tr td.selected:active,
  .datepicker table tr td.selected:hover:active,
  .datepicker table tr td.selected.disabled:active,
  .datepicker table tr td.selected.disabled:hover:active,
  .datepicker table tr td.selected.active,
  .datepicker table tr td.selected:hover.active,
  .datepicker table tr td.selected.disabled.active,
  .datepicker table tr td.selected.disabled:hover.active {
    background-color: #666666 \9;
  }
  .datepicker table tr td.active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: linear-gradient(to bottom, #08c, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.active.disabled:hover,
  .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.active,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled:hover.active,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active:hover.disabled,
  .datepicker table tr td.active.disabled.disabled,
  .datepicker table tr td.active.disabled:hover.disabled,
  .datepicker table tr td.active[disabled],
  .datepicker table tr td.active:hover[disabled],
  .datepicker table tr td.active.disabled[disabled],
  .datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.active,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled:hover.active {
    background-color: #003399 \9;
  }
  .datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px;
  }
  .datepicker table tr td span:hover,
  .datepicker table tr td span.focused {
    background: #eee;
  }
  .datepicker table tr td span.disabled,
  .datepicker table tr td span.disabled:hover {
    background: none;
    color: #999;
    cursor: default;
  }
  .datepicker table tr td span.active,
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: linear-gradient(to bottom, #08c, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .datepicker table tr td span.active:hover,
  .datepicker table tr td span.active:hover:hover,
  .datepicker table tr td span.active.disabled:hover,
  .datepicker table tr td span.active.disabled:hover:hover,
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled:hover.active,
  .datepicker table tr td span.active.disabled,
  .datepicker table tr td span.active:hover.disabled,
  .datepicker table tr td span.active.disabled.disabled,
  .datepicker table tr td span.active.disabled:hover.disabled,
  .datepicker table tr td span.active[disabled],
  .datepicker table tr td span.active:hover[disabled],
  .datepicker table tr td span.active.disabled[disabled],
  .datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc;
  }
  .datepicker table tr td span.active:active,
  .datepicker table tr td span.active:hover:active,
  .datepicker table tr td span.active.disabled:active,
  .datepicker table tr td span.active.disabled:hover:active,
  .datepicker table tr td span.active.active,
  .datepicker table tr td span.active:hover.active,
  .datepicker table tr td span.active.disabled.active,
  .datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399 \9;
  }
  .datepicker table tr td span.old,
  .datepicker table tr td span.new {
    color: #999;
  }
  .datepicker .datepicker-switch {
    width: 145px;
  }
  .datepicker .datepicker-switch,
  .datepicker .prev,
  .datepicker .next,
  .datepicker tfoot tr th {
    cursor: pointer;
  }
  .datepicker .datepicker-switch:hover,
  .datepicker .prev:hover,
  .datepicker .next:hover,
  .datepicker tfoot tr th:hover {
    background: #eee;
  }
  .datepicker .prev.disabled,
  .datepicker .next.disabled {
    visibility: hidden;
  }
  .datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  .input-append.date .add-on,
  .input-prepend.date .add-on {
    cursor: pointer;
  }
  .input-append.date .add-on i,
  .input-prepend.date .add-on i {
    margin-top: 3px;
  }
  .input-daterange input {
    text-align: center;
  }
  .input-daterange input:first-child {
    border-radius: 3px 0 0 3px;
  }
  .input-daterange input:last-child {
    border-radius: 0 3px 3px 0;
  }
  .input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 20px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
  }
  .datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    color: #333333;
    font-size: 13px;
    line-height: 20px;
  }
  .datepicker.dropdown-menu th,
  .datepicker.datepicker-inline th,
  .datepicker.dropdown-menu td,
  .datepicker.datepicker-inline td {
    padding: 4px 5px;
  }
  /*# sourceMappingURL=bootstrap-datepicker.standalone.css.map */
