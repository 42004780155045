

html body .content .search{
    display: flex;
    align-items: center;
    position: relative;
}
html body .content .search__input{
    width: 100%;
    border: none;
    background-color: white;
    padding: 2rem 3rem;
    padding-left: 7rem;
    border-radius: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--text);
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
    transition: var(--transition);
}
html body .content .search__input:focus{
    outline: 0;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}
html body .content .search__input:hover{
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}
html body .content .search::before{
    content: url('../../../assets/icons/szukaj.svg');
    position: absolute;
    left: 3rem;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
