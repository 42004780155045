.carousel-control-prev, .carousel-control-next{
    width: auto;
}
.carousel-control-prev > span, .carousel-control-next > span{
    color: white;
    font-size: 2.5rem;
    background-color: var(--textLight);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}