.cart__back{
    font-weight: 600;
    color: var(--text);
    font-size: 1.6rem;
}
.address{
    cursor: pointer;
}
.address__label{
    font-weight: 700;
}
.cart__address input[type="radio"]{
    position: absolute;
    right: 40px;
}
.address{
    transition: var(--transition);
}
.address.active{
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
}